export const STICKY_SENTINEL_HEIGHT = 1; // px

/**
 * Determines if the given bounding client rect has a valid y position.
 * @param topOffset - The distance from the height of the top of the window (or parent node)
 * to the point where we want the sticky element to stick.
 * @param boundingClientRect - The bounding client rect to check.
 * @returns A boolean indicating whether or not the bounding client rect has a valid y position.
 */
function isValidYPosition(
	topOffset: number,
	{ boundingClientRect }: { boundingClientRect: DOMRectReadOnly }
) {
	return boundingClientRect.y < topOffset;
}

/**
 * Svelte action that dispatches a custom stuck event when a node becomes stuck or unstuck (position: sticky is having an effect)
 * @param node  - the node the action is placed on
 * @param params.callback - function to execute when the node becomes stuck or unstuck
 */
export const sticky = (
	node: Node,
	{
		onStuck,
		topOffset = 0
	}: { onStuck?: (e: CustomEvent<{ isStuck: boolean }>) => void; topOffset: number }
) => {
	function intersectionCallback(entries: IntersectionObserverEntry[]) {
		// only observing one item at a time
		const entry = entries[0];

		let isStuck = false;
		if (!entry.isIntersecting && isValidYPosition(topOffset, entry)) {
			isStuck = true;
		}

		const evt = new CustomEvent('stuck', {
			detail: { isStuck }
		});

		node.dispatchEvent(evt);

		if (onStuck) {
			onStuck(evt);
		}
	}

	function prependSentinelIfNecessary() {
		const { parentNode: topParent } = stickySentinelTop;

		if (stickySentinelTop !== topParent?.firstChild) {
			topParent?.prepend(stickySentinelTop);
		}
	}

	function mutationCallback(mutations: MutationRecord[]) {
		// If something changes and the sentinel nodes are no longer first and last child, put them back in position
		mutations.forEach(() => prependSentinelIfNecessary());
	}

	const intersectionObserver = new IntersectionObserver(intersectionCallback, {});
	const mutationObserver = new MutationObserver(mutationCallback);

	// we insert and observe a sentinel node immediately after the target
	// when it is visible, we know the target node is not sticking
	const stickySentinelTop = document.createElement('div');
	stickySentinelTop.classList.add('stickySentinelTop');

	// without setting a height, Safari breaks
	node.parentNode?.prepend(stickySentinelTop);

	intersectionObserver.observe(stickySentinelTop);

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	mutationObserver.observe(node.parentNode!, { childList: true });

	return {
		destroy() {
			intersectionObserver.disconnect();
			mutationObserver.disconnect();
		},

		update() {
			intersectionObserver.observe(stickySentinelTop);
		}
	};
};
